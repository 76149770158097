const Modal = {
  baseStyle: {
    dialog: {
      bg: "brand.800",
      color: "white"
    }
  }
};

export default Modal;
