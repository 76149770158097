import { createIcon } from "@chakra-ui/react";

const RedThermometer = createIcon({
  displayName: "RedThermometer",
  viewBox: "0 0 32 101",
  path: (
    <>
      <path d="M26 70H31" stroke="white" strokeLinecap="round" />
      <path d="M26 54H31" stroke="white" strokeLinecap="round" />
      <path d="M26 66H28.1053" stroke="white" strokeLinecap="round" />
      <path d="M26 62H28.1053" stroke="white" strokeLinecap="round" />
      <path d="M26 58H28.1053" stroke="white" strokeLinecap="round" />
      <path d="M26 38H31" stroke="white" strokeLinecap="round" />
      <path d="M26 50H28.1053" stroke="white" strokeLinecap="round" />
      <path d="M26 46H28.1053" stroke="white" strokeLinecap="round" />
      <path d="M26 42H28.1053" stroke="white" strokeLinecap="round" />
      <path d="M26 22H31" stroke="white" strokeLinecap="round" />
      <path d="M26 6H31" stroke="white" strokeLinecap="round" />
      <path d="M26 34H28.1053" stroke="white" strokeLinecap="round" />
      <path d="M26 18H28.1053" stroke="white" strokeLinecap="round" />
      <path d="M26 30H28.1053" stroke="white" strokeLinecap="round" />
      <path d="M26 14H28.1053" stroke="white" strokeLinecap="round" />
      <path d="M26 26H28.1053" stroke="white" strokeLinecap="round" />
      <path d="M26 10H28.1053" stroke="white" strokeLinecap="round" />
      <circle cx="16" cy="85" r="16" fill="white" />
      <rect x="8" width="15" height="82" rx="7.5" fill="white" />
      <circle cx="16" cy="85" r="9" fill="#E53E3E" />
      <rect x="12" y="4" width="7" height="82" rx="3.5" fill="#CDCDCD" />
      <rect x="12" y="61" width="7" height="25" rx="3.5" fill="#E53E3E" />
    </>
  )
});

export default RedThermometer;
